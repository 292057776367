<template>
  <div class="case">
    <div class="case-title"><span class="t1">案例</span>>最新动态</div>
    <div class="main">
      <div class="main-title">最新动态</div>
      <div class="main-item" v-for="n in 5">
        <div class="img"></div>
        <div class="info">
          <div class="info-title">关于红包提现协议更新</div>
          <div class="info-content">
            {{n}}
            a阿大厦大厦大厦大厦大厦大阿是大大的撒上大阿是大厦大厦大厦大厦大a s da s da s
            da s da s da s
            d阿萨是大厦大厦大厦将我带你去大妈大妈萨达姆时代怕什么请我们去我们迫切没打破阿是多么怕的是冒泡赛打冒泡赛大妈拍摄的么阿么时代怕没收到怕什阿是大厦的
          </div>
          <div class="info-date">2022-11-10 10:32</div>
        </div>
      </div>

      <div class="card"></div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.case {
  width: 100%;
  min-height: 1427px;
  // display: flex;
  // justify-content: center;
  // flex-direction: column;
  // align-items: center;
  .case-title {
    margin-top: 40px;
    margin-left: 300px;
    font-size: 24px;
    .t1 {
      color: rgba(0, 0, 0, 0.4);
    }
  }
  .main {
      width: 840px;

    margin-top: 30px;
    margin-left: 396px;
    position: relative;
    .card {
      width: 264px;
      height: 275px;
      background: #fff;
      position: absolute;
      top: -25px;
      right: -20px;
      transform: translateX(100%);
    }
    .main-title {
      width: 840px;
      height: 86px;
      background: #f8f8f8;
      padding: 24px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: #333333;
      font-size: 28px;
      background: #f8f8f8;
    }
    .main-item {
      width: 840px;
      height: 235px;
      background: #ffffff;
      margin-bottom: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      .img {
        width: 272px;
        height: 153px;
        background: #d9d9d9;
      }
      .info {
        margin-left: 20px;
        .info-title {
          color: #333333;
          font-size: 32px;
          font-weight: 500;
        }
        .info-content {
          margin-top: 7px;
          width: 492px;
          color: rgba(0, 0, 0, 0.6);
          font-size: 16px;
          font-weight: 500;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
        .info-date {
          margin-top: 13px;

          color: rgba(0, 0, 0, 0.6);
          font-size: 16px;
          font-weight: 400;
        }
      }
    }
  }
}
</style>
